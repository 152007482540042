import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { AlertService } from './services/alert.service';
import { NavigationService } from './services/navigation.service';
import { PhoneCodesService } from './services/phone-codes/phone-codes.service';
import { StorageService } from './services/storage.service';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AlertService,
    NavigationService,
    PhoneCodesService,
    StorageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
