import { Injectable } from '@angular/core';

import { AlertService } from './alert.service';
import { NavigationService } from './navigation.service';

import { User } from '../../types/user';
import { USER_REGISTRATION } from '../../utils/constants';
import { homeRoute } from '../../utils/app-routes';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    private alert: AlertService,
    private navigation: NavigationService
  ) {}

  public set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public get<T>(key: string): T {
    const value = localStorage.getItem(key);

    return JSON.parse(value) as T;
  }

  public clear() {
    localStorage.clear();
  }

  private b64ToUTF8(text: string) {
    return decodeURIComponent(escape(window.atob(text)));
  }

  companyName() {
    const user = this.get<User>(USER_REGISTRATION);
    const { company } = user;

    if (company === undefined || company === null || !company) {
      this.alert.warning(
        'Es necesario obtener token para conectar con nuestros servicios'
      );

      this.navigation.navigateByUrl(homeRoute);
    }

    return this.b64ToUTF8(company);
  }
}
