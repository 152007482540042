import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { StorageService } from '../storage.service';

import { environment } from '../../../environments/environment';
import { PhoneCode } from './types';

@Injectable({
  providedIn: 'root',
})
export class PhoneCodesService {
  private readonly getCodesURL = 'api/codemobile/GetCodesExternal';
  codes: PhoneCode[] = [];

  constructor(private http: HttpClient, private storage: StorageService) {}

  getCodes(): Observable<PhoneCode[]> {
    if (this.codes.length > 0) return of(this.codes);

    const base = this.storage.companyName();
    const headersMap = {
      Authorization: environment.publicKey,
    };
    const headers = new HttpHeaders(headersMap);

    return this.http.get<PhoneCode[]>(`${base}${this.getCodesURL}`, {
      headers,
    });
  }

  setCodes(newCodes: PhoneCode[]) {
    this.codes = newCodes;
  }
}
