import { Injectable } from '@angular/core';
import { ToastController, AlertController, AlertOptions } from '@ionic/angular';

import { ALERT_LOADER } from '../../utils/constants';

import { ToastOptions } from '../../types/ionic-components';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private toastController: ToastController,
    public alertController: AlertController
  ) {}

  async presentToast(toastOptions: ToastOptions) {
    const toast = await this.toastController.create(toastOptions);

    await toast.present();
  }

  async basic(
    message: string,
    color: string = 'light',
    duration = ALERT_LOADER
  ) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'top',
      color,
    });

    await toast.present();
  }

  async warning(text: string = '') {
    const options: ToastOptions = {
      color: 'warning',
      duration: ALERT_LOADER,
      showCloseButton: false,
      message: text || 'Ha ocurrido un error',
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'sad-outline',
          handler: () => {},
        },
        {
          side: 'end',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    };

    const toast = await this.toastController.create(options);

    await toast.present();
  }

  async prompt(options: AlertOptions) {
    const alert = await this.alertController.create(options);

    await alert.present();
  }
}
